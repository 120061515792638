import axios from '@/common/axios'
import qs from 'qs'

export function addSampleInvestigation (data) {
  return axios({
    method: 'post',
    url: '/quality/sampleInvestigation/add',
    data: qs.stringify(data)
  })
}

export function deleteSampleInvestigation (id) {
  return axios({
    method: 'delete',
    url: '/quality/sampleInvestigation/delete/' + id
  })
}

export function updateSampleInvestigation (data) {
  return axios({
    method: 'put',
    url: '/quality/sampleInvestigation/update',
    data: qs.stringify(data)
  })
}

export function selectSampleInvestigationInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/sampleInvestigation/info/' + id
  })
}

export function selectSampleInvestigationList (query) {
  return axios({
    method: 'get',
    url: '/quality/sampleInvestigation/list',
    params: query
  })
}

export function reviewSampleInvestigation (data) {
  return axios({
    method: 'put',
    url: '/quality/sampleInvestigation/review',
    data: qs.stringify(data)
  })
}
