<template>
  <div id="sampleInvestigation">
    <el-dialog
      :title="sampleInvestigationFormTitle"
      width="1200px"
      :visible.sync="sampleInvestigationDialogVisible"
      :close-on-click-modal="false"
      @close="sampleInvestigationDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="sampleInvestigationFormRef"
        :model="sampleInvestigationForm"
        :rules="sampleInvestigationFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="样品名称" prop="sampleName">
              <el-input
                v-model="sampleInvestigationForm.sampleName"
                placeholder="请输入样品名称"
                clearable
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格" prop="spec">
              <el-input
                v-model="sampleInvestigationForm.spec"
                placeholder="请输入规格"
                clearable
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产批号" prop="batchNo">
              <el-input
                v-model="sampleInvestigationForm.batchNo"
                placeholder="请输入生产批号"
                clearable
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="灭菌批号" prop="sterilizationBatchNo">
              <el-input
                v-model="sampleInvestigationForm.sterilizationBatchNo"
                placeholder="请输入灭菌批号"
                clearable
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="考察目的" prop="purpose">
              <el-radio-group
                v-model="sampleInvestigationForm.purpose"
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              >
                <el-radio :label="1">
                  常规留样观察
                </el-radio>
                <el-radio :label="2">
                  重点留样观察
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考察日期" prop="investigationDate">
              <el-date-picker
                v-model="sampleInvestigationForm.investigationDate"
                placeholder="请选择考察日期"
                value-format="yyyy-MM-dd"
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考察项目及指标" prop="itemsIndicators">
              <el-input
                v-model="sampleInvestigationForm.itemsIndicators"
                placeholder="请输入考察项目及指标"
                type="textarea"
                :rows="5"
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="考察结果" prop="result">
              <el-input
                v-model="sampleInvestigationForm.result"
                placeholder="请输入考察结果"
                type="textarea"
                :rows="5"
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="sampleInvestigationForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="5"
                :disabled="sampleInvestigationFormTitle !== '新增留样考察记录'
                  && sampleInvestigationFormTitle !== '修改留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="sampleInvestigationForm.reviewResult"
                :disabled="sampleInvestigationFormTitle !== '复核留样考察记录'
                  && sampleInvestigationFormTitle !== '留样考察记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="sampleInvestigationFormTitle === '留样考察记录详情'">
          <el-col :span="8">
            <el-form-item label="记录人" prop="recorder">
              <el-input v-model="sampleInvestigationForm.recorder" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="记录日期" prop="recordDate">
              <el-input v-model="sampleInvestigationForm.recordDate" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="sampleInvestigationFormTitle === '留样考察记录详情'">
          <el-col :span="8">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="sampleInvestigationForm.reviewer" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核日期" prop="reviewDate">
              <el-input v-model="sampleInvestigationForm.reviewDate" readonly />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="sampleInvestigationDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="sampleInvestigationFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="sampleInvestigationFormTitle === '留样考察记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="样品名称">
        <el-input v-model="searchForm.sampleName" placeholder="请输入样品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['ZL_SAMPLE_INVESTIGATION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="sampleInvestigationPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="sampleName" label="样品名称" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="batchNo" label="生产批号" />
      <el-table-column prop="sterilizationBatchNo" label="灭菌批号" />
      <el-table-column label="考察目的">
        <template slot-scope="scope">
          <span v-if="scope.row.purpose === 1">常规留样观察</span>
          <span v-if="scope.row.purpose === 2">重点留样观察</span>
        </template>
      </el-table-column>
      <el-table-column label="考察日期">
        <template slot-scope="scope">
          <span v-if="scope.row.investigationDate">{{ scope.row.investigationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="itemsIndicators" label="考察项目及指标" />
      <el-table-column prop="result" label="考察结果" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column label="记录日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="复核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ZL_SAMPLE_INVESTIGATION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['ZL_SAMPLE_INVESTIGATION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['ZL_SAMPLE_INVESTIGATION_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="sampleInvestigationPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSampleInvestigation,
  deleteSampleInvestigation,
  updateSampleInvestigation,
  selectSampleInvestigationInfo,
  selectSampleInvestigationList,
  reviewSampleInvestigation
} from '@/api/quality/sampleInvestigation'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      sampleInvestigationDialogVisible: false,
      sampleInvestigationFormTitle: '',
      sampleInvestigationForm: {
        id: '',
        sampleName: '',
        spec: '',
        batchNo: '',
        sterilizationBatchNo: '',
        purpose: '',
        investigationDate: '',
        itemsIndicators: '',
        result: '',
        remarks: '',
        reviewResult: '',
        taskId: ''
      },
      sampleInvestigationPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        sampleName: ''
      }
    }
  },
  created () {
    selectSampleInvestigationList(this.searchForm).then(res => {
      this.sampleInvestigationPage = res
    })
  },
  methods: {
    sampleInvestigationDialogClose () {
      this.$refs.sampleInvestigationFormRef.resetFields()
    },
    sampleInvestigationFormSubmit () {
      if (this.sampleInvestigationFormTitle === '留样考察记录详情') {
        this.sampleInvestigationDialogVisible = false
        return
      }
      this.$refs.sampleInvestigationFormRef.validate(async valid => {
        if (valid) {
          if (this.sampleInvestigationFormTitle === '新增留样考察记录') {
            this.sampleInvestigationForm.id = ''
            this.sampleInvestigationForm.status = 1
            await addSampleInvestigation(this.sampleInvestigationForm)
          } else if (this.sampleInvestigationFormTitle === '修改留样考察记录') {
            await updateSampleInvestigation(this.sampleInvestigationForm)
          } else if (this.sampleInvestigationFormTitle === '复核留样考察记录') {
            await reviewSampleInvestigation(this.sampleInvestigationForm)
          }
          this.sampleInvestigationPage = await selectSampleInvestigationList(this.searchForm)
          this.sampleInvestigationDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.sampleInvestigationFormTitle = '新增留样考察记录'
      this.sampleInvestigationDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSampleInvestigation(row.id)
        if (this.sampleInvestigationPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.sampleInvestigationPage = await selectSampleInvestigationList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.sampleInvestigationFormTitle = '修改留样考察记录'
      this.sampleInvestigationDialogVisible = true
      this.selectSampleInvestigationInfoById(row.id)
    },
    handleReview (index, row) {
      this.sampleInvestigationFormTitle = '复核留样考察记录'
      this.sampleInvestigationDialogVisible = true
      this.selectSampleInvestigationInfoById(row.id)
    },
    handleInfo (index, row) {
      this.sampleInvestigationFormTitle = '留样考察记录详情'
      this.sampleInvestigationDialogVisible = true
      this.selectSampleInvestigationInfoById(row.id)
    },
    selectSampleInvestigationInfoById (id) {
      selectSampleInvestigationInfo(id).then(res => {
        this.sampleInvestigationForm.id = res.id
        this.sampleInvestigationForm.sampleName = res.sampleName
        this.sampleInvestigationForm.spec = res.spec
        this.sampleInvestigationForm.batchNo = res.batchNo
        this.sampleInvestigationForm.sterilizationBatchNo = res.sterilizationBatchNo
        this.sampleInvestigationForm.purpose = res.purpose
        this.sampleInvestigationForm.investigationDate = res.investigationDate
        this.sampleInvestigationForm.itemsIndicators = res.itemsIndicators
        this.sampleInvestigationForm.result = res.result
        this.sampleInvestigationForm.remarks = res.remarks
        this.sampleInvestigationForm.reviewResult = res.reviewResult
        this.sampleInvestigationForm.taskId = res.taskId
        this.sampleInvestigationForm.recorder = res.recorder
        this.sampleInvestigationForm.recordDate = res.recordDate.substring(0, 10)
        this.sampleInvestigationForm.reviewer = res.reviewer
        this.sampleInvestigationForm.reviewDate = res.reviewDate.substring(0, 10)
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSampleInvestigationList(this.searchForm).then(res => {
        this.sampleInvestigationPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSampleInvestigationList(this.searchForm).then(res => {
        this.sampleInvestigationPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSampleInvestigationList(this.searchForm).then(res => {
        this.sampleInvestigationPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['ZL_SAMPLE_INVESTIGATION_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `留样考察记录${this.sampleInvestigationForm.investigationDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
